//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SharedContent',
  inheritAttrs: false,
  props: {
    reference: {
      type: [Object, String],
      default: undefined
    }
  },
  setup (props) {
    const content = props.reference?.content || { component: 'div' }

    return {
      content
    }
  }
})
